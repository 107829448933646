import { Search } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import React from "react";

import styled from "styled-components/macro";

class SearchButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchVisible: false,
    };
  }

  SearchVisible = () => {
    this.setState({ searchVisible: true });
  };

  SearchHidden = () => {
    this.setState({ searchVisible: false });
  };

  onKeyUp = (event) => {
    if (event.keyCode === 27) {
      this.SearchHidden();
    }
  };

  render() {
    if (this.state.searchVisible === true) {
      return (
        <TextField
          label="Search"
          startIcon={<Search />}
          onChange={this.props.onChange}
          onKeyUp={this.onKeyUp}
          variant="outlined"
          size="small"
          autoFocus
          InputProps={{
            startAdornment: <Search />,
          }}
        />
      );
    } else {
      return (
        <Button
          startIcon={<Search />}
          variant="outlined"
          onClick={this.SearchVisible}
        >
          Search
        </Button>
      );
    }
  }
}

export default SearchButton;
