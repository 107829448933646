/* DA Sample Data */

const range = (start, end) => {
  var result = [];

  for (let i = start; i <= end; i++) {
    result.push(i);
  }

  return result;
};

const choose = (size, dataSet) => {
  var result = [];

  for (let i = 0; i < size; i++) {
    var rand_num = Math.floor(Math.random() * dataSet.length);

    result.push(dataSet[rand_num]);
  }

  return result;
};

const WARRANTYSTATUS = [
  "New",
  "Pending",
  "Accepted",
  "Declined",
  "Info Requested",
  "Resubmitted",
  "Work Complete",
  "Paid",
];

const DEALERS = [
  "Sea-Alliance Ltd.",
  "Midlands Nautique Ltd.",
  "Nautique Spain",
  "Race Unlimited",
  "Sea-Alliance Ltd.",
  "Surf Seven Sport",
  "Nautica Zola",
  "Ski Marine AB",
];

const MANUFACTURES = ["Nautique"];

const PRODUCTS = [
  "2022 G23",
  "2022 S21",
  "2022 G23 Paragon ",
  "20222 GS24",
  "2022 S33",
];

const ENGINES = ["Monsoon M5Di, 350 HP"];

const Id = (size) => {
  var initial = 441481;
  return range(initial, initial + size);
};

const WarrantyStatus = (size) => {
  return choose(size, WARRANTYSTATUS);
};

const Dealers = (size) => {
  return choose(size, DEALERS);
};

const Manufuactures = (size) => {
  return choose(size, MANUFACTURES);
};

const Products = (size) => {
  return choose(size, PRODUCTS);
};

const Engines = (size) => {
  return choose(size, ENGINES);
};

const Dates = (size, start = null, end = null) => {
  if (start === null) {
    start = new Date(2020, 1, 1);
  }

  if (end === null) {
    start = new Date(2021, 12, 30);
  }

  var result = [];

  for (let i = 0; i < size; i++) {
    //var rand_num = Math.random() * dataSet.length + 1;
    // TODO make this not suck
    result.push(new Date());
  }

  return result;
};

const ShortDates = (size) => {
  var result = [];

  for (let i = 0; i < size; i++) {
    var day = Math.floor(Math.random() * 28 + 1);
    var month = Math.floor(Math.random() * 12 + 1);
    var year = Math.floor(Math.random() * 20 + 2010);

    result.push(`${month}/${day}/${year}`);
  }

  return result;
};

const Currency = (size) => {
  var result = [];

  for (let i = 0; i < size; i++) {
    var rand_num = Math.floor(Math.random() * 10000);
    result.push(`\$${rand_num}.00`);
  }

  return result;
};

const WarrantyData = (size) => {
  return {
    ID: Id(size),
    Status: WarrantyStatus(size),
    Dealers: Dealers(size),
    Manufuactures: Manufuactures(size),
    Products: Products(size),
    Engines: Engines(size),
    Created: ShortDates(size),
    Modified: ShortDates(size),
    "Total Claim": Currency(size),
  };
};

const DataFactory = (key, size) => {
  var data = {
    Warranty: WarrantyData,
  }[key](size);

  var result = [];

  for (let i = 0; i < size; i++) {
    //create empty dicts
    result.push({});
  }

  for (const key in data) {
    for (let i = 0; i < size; i++) {
      result[i][key] = data[key][i];
    }
  }

  return result;
};

export default DataFactory;
