import React from "react";

import styled, { withTheme } from "styled-components/macro";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const ButtonContainer = styled.div`
  display: "flex";
  text-align: right;
`;

function MUIOrders() {
  return (
    <div>
      <ButtonContainer>
        <Button variant="outlined">Export</Button>
        <Button variant="outlined">Search</Button>
        <Button variant="outlined">Filter</Button>
        <Button variant="outlined">Sort</Button>
        <Button variant="outlined">Columns</Button>
      </ButtonContainer>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>h1</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>1</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

export default MUIOrders;
