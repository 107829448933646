import {
  Announcement,
  BubbleChart,
  HeadsetMic,
  Help,
  Info,
  Lock,
  MonetizationOn,
  Person,
  Policy,
  Settings,
  ShoppingBag,
  Today,
} from "@mui/icons-material";

const pagesSection = [
  {
    href: "/dashboard",
    icon: Person,
    title: "Customers",
  },
  {
    href: "/orders",
    icon: ShoppingBag,
    title: "MUI Orders",
  },
  {
    href: "/dashboard",
    icon: Today,
    title: "Slotting",
  },
  {
    href: "/warranty",
    icon: Policy,
    title: "Warranty",
  },
  {
    href: "/dashboard",
    icon: Settings,
    title: "Parts",
  },
  {
    href: "/dashboard",
    icon: MonetizationOn, //circle $
    title: "Financial",
  },
  {
    href: "/dashboard",
    icon: BubbleChart,
    title: "Dealer Inventory",
  },
  {
    href: "/dashboard",
    icon: Announcement, //radio podcast
    title: "Service Bulletins",
  },
  {
    href: "/dashboard",
    icon: Info,
    title: "Dealer Portal",
  },
  {
    href: "/dashboard",
    icon: Lock,
    title: "Admin",
  },
];

const SupportSection = [
  {
    href: "/dashboard",
    icon: HeadsetMic,
    title: "Support",
  },
  {
    href: "/dashboard",
    icon: Help,
    title: "Help",
  },
];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
  {
    title: "Support",
    pages: SupportSection,
  },
];

export default navItems;
