import React from "react";

import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { FileDownload, Filter, Sort, ViewColumn } from "@mui/icons-material";

import SearchButton from "../components/SearchButton";

import styled from "styled-components/macro";

class TableView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: "",
    };

    console.dir(this.data);
  }

  setSearchString = (value) => {
    this.setState({ searchString: value.target.value });
  };

  render() {
    return (
      <div>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button variant="outlined" startIcon={<FileDownload />}>
            Export
          </Button>
          <SearchButton onChange={this.setSearchString} />
          <Button variant="outlined" startIcon={<Filter />}>
            Filter
          </Button>
          <Button variant="outlined" startIcon={<Sort />}>
            Sort
          </Button>
          <Button variant="outlined" startIcon={<ViewColumn />}>
            Columns
          </Button>
        </Stack>

        <Table>
          <TableHead>
            <TableRow>
              {this.props.columns.map((column) => {
                return <TableCell>{column}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>{this.props.children}</TableBody>
        </Table>
      </div>
    );
  }
}

export default TableView;
