import { MoreVert } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";

import TableView from "../components/TableView";
import DataFactory from "../DataFactory";

class Warranty extends React.Component {
  constructor(props) {
    super(props);
  }

  statusToColor(status) {
    switch (status) {
      case "Work Complete":
      case "Paid":
        return "success";
      case "Accepted":
        return "primary";
      case "Info Requested":
        return "secondary";
      case "Resubmitted":
      case "Pending":
        return "warning";
      case "Declined":
        return "error";
      default:
        return "default";
    }
  }

  render() {
    const columns = [
      "ID",
      "Status",
      "Dealers",
      "Manufuactures",
      "Products",
      "Engines",
      "Created",
      "Modified",
      "Total Claim",
    ];

    this.data = DataFactory("Warranty", 200);

    return (
      <>
        <Stack direction="column" spacing={4}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Stack
              direction="column"
              spacing={2}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Card maxWidth="500px">
                <CardHeader
                  title="YTD"
                  action={
                    <IconButton>
                      <MoreVert />
                    </IconButton>
                  }
                ></CardHeader>
                <CardContent>
                  <img src="static/img/warranty/YTD.png"></img>
                </CardContent>
              </Card>
              <Card>
                <CardHeader
                  title="YTD Warranty Dollars"
                  action={
                    <IconButton>
                      <MoreVert />
                    </IconButton>
                  }
                ></CardHeader>
                <CardContent>
                  <img src="static/img/warranty/YTD Warranty Dollars.png"></img>
                </CardContent>
              </Card>
            </Stack>

            <Card>
              <CardHeader
                title="Claims Per Warranty Specalist"
                action={
                  <IconButton>
                    <MoreVert />
                  </IconButton>
                }
              ></CardHeader>
              <CardContent>
                <img src="static/img/warranty/Claims Per Warranty.png"></img>
              </CardContent>
            </Card>

            <Card>
              <CardHeader
                title="Annual Quality Category"
                action={
                  <IconButton>
                    <MoreVert />
                  </IconButton>
                }
              ></CardHeader>
              <CardContent>
                <img src="static/img/warranty/Annual Quality.png"></img>
              </CardContent>
            </Card>
          </Stack>
          <Divider />
          <TableView columns={columns}>
            {this.data.map((item) => {
              return (
                <TableRow>
                  <TableCell>
                    <Link href={`/warranty/${item["ID"]}`}>{item["ID"]}</Link>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={item["Status"]}
                      size="small"
                      color={this.statusToColor(item["Status"])}
                    ></Chip>
                  </TableCell>
                  <TableCell>
                    <Link href="#">{item["Dealers"]}</Link>
                  </TableCell>
                  <TableCell>
                    <Link href="#">{item["Manufuactures"]}</Link>
                  </TableCell>
                  <TableCell>{item["Products"]}</TableCell>
                  <TableCell>{item["Engines"]}</TableCell>
                  <TableCell>{item["Created"]}</TableCell>
                  <TableCell>{item["Modified"]}</TableCell>
                  <TableCell>{item["Total Claim"]}</TableCell>
                </TableRow>
              );
            })}
          </TableView>
        </Stack>
      </>
    );
  }
}

export default Warranty;
